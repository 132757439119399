/**
 * Constants for the application.
 */
export const Constants = {
  AUTH_TOKEN: "AUTH_TOKEN_rev3",
  // Localstorage key to persist last used team between reloads.
  teamCacheKey: "TEAM_CACHE",
  // Localstorage key to persist link comment state between reloads.
  commentCacheKey: "COMMENT_CACHE",
  // Localstorage key to persist "include message" toggle state.
  includeMessagePreferenceKey: "INCLUDE_MESSAGE_PREFERENCE_CACHE",
};
