export class Config {
  public static PROD = import.meta.env.PROD;
  public static CLIENT_URL = import.meta.env.VITE_CLIENT_URL;
  public static GRAPHQL_URL = import.meta.env.VITE_GRAPHQL_URL;

  public static OAUTH_URL = import.meta.env.VITE_OAUTH_URL;
  public static OAUTH_CLIENT_ID = import.meta.env.VITE_OAUTH_CLIENT_ID;
  public static OAUTH_REDIRECT_URI = import.meta.env.VITE_OAUTH_REDIRECT_URI;
  public static OAUTH_TOKEN_URL = import.meta.env.VITE_OAUTH_TOKEN_URL;
}
