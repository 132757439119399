import styled from "styled-components";
import { Text } from "@linear/orbiter/components/Text";

/** Link component */
export const Link = styled(Text).attrs({
  type: "smallPlus",
  color: "controlPrimary",
})`
  cursor: default !important;

  &:hover {
    color: ${props => props.theme.color.controlPrimaryHover};
    text-decoration: none;
  }
`;
