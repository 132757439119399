import React from "react";
import Front from "@frontapp/plugin-sdk";
import { useMutation } from "urql";
import styled from "styled-components";
import { differenceInMinutes } from "date-fns";
import { Button } from "@linear/orbiter/components/Button";
import { Text } from "@linear/orbiter/components/Text";
import { Config } from "../config";

const OAUTH_SCOPE = ["read", "write"];
const authUrl = `${Config.OAUTH_URL}?client_id=${Config.OAUTH_CLIENT_ID}&response_type=stringauth&redirect_uri=${
  Config.OAUTH_REDIRECT_URI
}&scope=${OAUTH_SCOPE.join(",")}`;

const handleOpenUrl = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openUrl = (Front as any).openUrl;
  if (openUrl) {
    return openUrl(url);
  }

  window.open(url);
};

type Props = {
  onSuccess(accessToken: string): void;
};

interface OauthAuthStringChallengePayload {
  oauthAuthStringChallenge: {
    success: boolean;
    authString: string;
  };
}

interface OauthAuthStringCheckPayload {
  oauthAuthStringCheck: {
    success: boolean;
    token?: string;
  };
}

export function LoginButton({ onSuccess }: Props) {
  const [authString, setAuthString] = React.useState<string>();
  const [, challengeMutation] = useMutation<OauthAuthStringChallengePayload>(`
    mutation OauthAuthStringChallenge($appId: String! $scope: [String!]!) {
      oauthAuthStringChallenge(appId: $appId, scope: $scope) {
        success
        authString
      }
    }
  `);

  const handleLogin = async () => {
    const challengeMutationResult = await challengeMutation({
      appId: Config.OAUTH_CLIENT_ID,
      scope: OAUTH_SCOPE,
    });
    setAuthString(challengeMutationResult.data?.oauthAuthStringChallenge.authString);
  };

  const handleReset = () => {
    setAuthString(undefined);
  };

  return (
    <Container>
      {authString ? (
        <AuthString authString={authString} onSuccess={onSuccess} onReset={handleReset} />
      ) : (
        <Button onClick={handleLogin}>Log in with Linear</Button>
      )}
    </Container>
  );
}

const AuthString = (props: { authString: string; onSuccess(accessToken: string): void; onReset(): void }) => {
  const { authString, onSuccess, onReset } = props;

  const [startedPollingAt] = React.useState<Date>(new Date());

  const [, checkMutation] = useMutation<OauthAuthStringCheckPayload>(`
    mutation OauthAuthStringCheck($appId: String! $authString: String!) {
      oauthAuthStringCheck(appId: $appId, authString: $authString) {
        success
        token
      }
    }
  `);

  const poll = async () => {
    // Stop polling and reset state after the token expires anyway.
    if (differenceInMinutes(new Date(), startedPollingAt) >= 5) {
      onReset();
      return;
    }

    const checkMutationResult = await checkMutation({
      appId: Config.OAUTH_CLIENT_ID,
      authString,
    });

    if (
      checkMutationResult.data?.oauthAuthStringCheck.success &&
      checkMutationResult.data?.oauthAuthStringCheck.token
    ) {
      onSuccess(checkMutationResult.data?.oauthAuthStringCheck.token);
      return;
    }
  };

  React.useEffect(() => {
    if (!authString) {
      return;
    }

    // Open the oAuth flow screen and provide the auth string.
    handleOpenUrl(authUrl + `&authstring=${authString}`);

    const interval = setInterval(poll, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <Text type="smallPlus" color="labelMuted">
        We've opened Linear in another window. Return to this screen when you've completed the authorization.
      </Text>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;
