import * as React from "react";
import styled from "styled-components";
import { fontSize } from "../styles/mixins";
import { ScreenSize, useScreenSize } from "../hooks/useScreenSize";
import { Text } from "./Text";
import { screenSize } from "./../styles/styled";

/** The props for the `FormLabel` component */
export interface FormLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  /** Marks the field as optional. */
  optional?: boolean;
  /** Additional help text. */
  helpText?: React.ReactNode;
  /** Whether or not the separator should be added. */
  noSeparator?: boolean;
  /** Label content. */
  children: React.ReactNode;
}

/**
 * Label for a form field.
 */
export function FormLabel(props: FormLabelProps) {
  const { optional = false, helpText, noSeparator = false, children, ...etc } = props;
  const isPhoneSize = useScreenSize(ScreenSize.phone);

  return (
    <label {...etc}>
      <Text type="smallPlus" color="labelBase">
        {children}
      </Text>
      {optional && (
        <StyledText type="small" color="labelMuted">
          (optional)
        </StyledText>
      )}
      {helpText && (
        <HelpText type="small" color="labelMuted">
          {!noSeparator && !isPhoneSize && <Separator color="labelFaint">-</Separator>}
          {helpText}
        </HelpText>
      )}
    </label>
  );
}

const StyledText = styled(Text)`
  margin-left: 8px;
`;

export const HelpText = styled(StyledText)`
  margin-left: 8px;

  ${screenSize.phone} {
    margin-left: 0;
    margin-bottom: 8px;
    margin-top: 4px;
    display: block;
    font-size: ${fontSize("mini")};
    line-height: 1.4;
  }
`;
export const Separator = styled(Text)`
  margin-right: 8px;
`;
