import * as React from "react";
import styled from "styled-components";
import { ColorConverter } from "../utils/ColorConverter";
import { moreHitArea } from "../styles/mixins";
import { highlight, transitionSpeed } from "./../styles/styled";

export interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // If true, the toggle will be smaller.
  small?: boolean;
}

/**
 * iOS inspired toggle component.
 */
export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
  const { small = false, onKeyDown, ...rest } = props;
  return (
    <ToggleControl
      {...rest}
      $small={small}
      ref={ref}
      onKeyDown={event => {
        // The "Enter" key should also toggle the checkbox, not just "Space".
        // It's pretty common for toggles to work this way, but not native for input checkboxes that we use as a primitive here.
        if (event.key === "Enter") {
          event.preventDefault();
          event.currentTarget.click();
        }
        // Call the `onKeyDown` prop if it is defined
        if (onKeyDown) {
          onKeyDown(event);
        }
      }}
    />
  );
});

const ToggleControl = styled.input.attrs({
  type: "checkbox",
})<{ $small: boolean }>`
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  width: 30px;
  height: 20px;
  background-color: ${({ theme }) =>
    ColorConverter.mixCss(theme.color.bgBase, theme.color.labelBase, theme.isDark ? 0.2 : 0.4)};
  border-radius: 72px;
  flex-shrink: 0;
  margin: 0;
  ${moreHitArea(6)}
  transition-duration: ${transitionSpeed("highlightFadeOut")};

  ${props => (props.disabled ? `opacity: 0.5;` : "")}

  &:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: ${props => props.theme.color.controlPrimaryLabel};
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: all 100ms ease-out;
  }

  &:checked {
    background: ${props => props.theme.color.controlPrimary};
  }

  &:checked:after {
    left: 13px;
    background: ${props => props.theme.color.controlPrimaryLabel};
  }

  ${props =>
    !props.disabled &&
    `
      &:${highlight} {
        background-color: ${ColorConverter.mixCss(
          props.theme.color.bgBase,
          props.theme.color.labelBase,
          props.theme.isDark ? 0.3 : 0.5
        )};
        transition-duration: ${transitionSpeed("highlightFadeIn")};
      }

      &:checked:${highlight} {
        background-color: ${props.theme.color.controlPrimaryHover};
      }
  `}

  /* Sizes */

  ${props =>
    props.$small &&
    `
    width: 22px;
    height: 14px;

    &:after {
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
    }

    &:checked:after {
      left: 10px;
    }
  `}

  transition: all 100ms ease-out;

  &:focus-visible {
    outline-offset: 2px;
  }
`;
