import React from "react";
import { createRoot } from "react-dom/client";
import { Config } from "./config";
import App from "./App";

const root = createRoot(self.document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/concepts/hot-module-replacement
if (!Config.PROD && import.meta.hot) {
  import.meta.hot.accept();
}
