import { useMedia } from "./react-use";

/** The supported screen sizes. */
export enum ScreenSize {
  phone = "phone",
  tablet = "tablet",
  laptop = "laptop",
  normal = "normal",
  large = "large",
  xlarge = "xlarge",
}

/** The breakpoints for each screen size. */
export const screenSizeBreakPoints: Record<ScreenSize, number> = {
  phone: 640,
  tablet: 768,
  laptop: 1024,
  normal: 1025,
  large: 1400,
  xlarge: 1800,
};

/** At which size the sidebar will always be collapsed on smaller screen. */
export const sidebarAlwaysCollapsedScreenSize: ScreenSize = ScreenSize.laptop;

/**
 * Helper function to get the media query for a given screen size.
 */
const getMediaQuery = (screenSize: ScreenSize): string => {
  switch (screenSize) {
    case ScreenSize.phone:
      return `(max-width: ${screenSizeBreakPoints.phone}px)`;
    case ScreenSize.tablet:
      return `(max-width: ${screenSizeBreakPoints.tablet}px)`;
    case ScreenSize.laptop:
      return `(max-width: ${screenSizeBreakPoints.laptop}px)`;
    case ScreenSize.large:
      return `(min-width: ${screenSizeBreakPoints.large}px)`;
    case ScreenSize.xlarge:
      return `(min-width: ${screenSizeBreakPoints.xlarge}px)`;
    default:
      return `(min-width: ${screenSizeBreakPoints.normal}px)`;
  }
};

/**
 * Hook to check if the user's screen is of a certain size.
 *
 * @returns Whether the current screen matches the given screen size. Screen size "tablet" includes very small
 * screens that are matched by the screen size "phone".
 */
export const useScreenSize = (screenSize: ScreenSize): boolean => {
  const query = getMediaQuery(screenSize);
  return useMedia(query);
};

/** Imperative function to check if the user's screen is of a certain size.
 * @param screenSize The screen size to check against
 * @returns A boolean indicating whether the current screen matches the given screen size
 */
export function getScreenSize(screenSize: ScreenSize): boolean {
  const query = getMediaQuery(screenSize);
  return window.matchMedia(query).matches;
}

/** Hook to get the current screen size. */
export const useGetScreenSize = (): ScreenSize => {
  const isPhone = useScreenSize(ScreenSize.phone);
  const isTablet = useScreenSize(ScreenSize.tablet);
  const isLaptop = useScreenSize(ScreenSize.laptop);
  const isLarge = useScreenSize(ScreenSize.large);
  const isXlarge = useScreenSize(ScreenSize.xlarge);

  if (isPhone) {
    return ScreenSize.phone;
  }

  if (isTablet) {
    return ScreenSize.tablet;
  }

  if (isLaptop) {
    return ScreenSize.laptop;
  }

  if (isXlarge) {
    return ScreenSize.xlarge;
  }

  if (isLarge) {
    return ScreenSize.large;
  }

  return ScreenSize.normal;
};
