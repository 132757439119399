import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { components, type ValueType } from "react-select";
import styled, { useTheme } from "styled-components";
import { Formik, Field as FormikField, Form, type FormikHelpers, type FormikProps, type FieldProps } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "urql";
import Front from "@frontapp/plugin-sdk";
import uniqBy from "lodash/uniqBy";
import { Button } from "@linear/orbiter/components/Button";
import { FormLabel } from "@linear/orbiter/components/FormLabel";
import { Input } from "@linear/orbiter/components/Input";
import { CloseIcon } from "@linear/orbiter/icons/base/CloseIcon";
import { InputError } from "@linear/orbiter/components/InputError";
import { Text } from "@linear/orbiter/components/Text";
import { SortHelper } from "@linear/common/utils/SortHelper";
import { Toggle } from "@linear/orbiter/components/Toggle";
import { Flex } from "@linear/orbiter/components/Flex";
import { attachmentForFrontConversation } from "~/utils/attachmentForFrontConversation";
import { defaultSelectProps, Select } from "~/components/Select";
import { hasImages } from "~/utils/formattingUtils";
import {
  CreateAttachmentMutation,
  CreateIssueMutation,
  type ICreateAttachmentMutation,
  type ICreateIssueMutation,
  type IIssueUpdateMutation,
  type ITeamQuery,
  type IViewerQuery,
  TeamQuery,
  UpdateIssueMutation,
} from "../queries";
import type { FrontData, Team } from "../types";
import { Constants } from "../constants";

const NewIssueSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  team: Yup.string().required("Required"),
  priority: Yup.number(),
  assignee: Yup.string(),
  labels: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
});

type Props = {
  data: FrontData;
  teams: Team[];
  priorities: IViewerQuery["issuePriorityValues"];
  onCreate(): void;
  onCancel(): void;
};

interface Option {
  value: string;
  label: string;
}

interface FormValues {
  title: string;
  description: string;
  includeMessage: boolean;
  team: string;
  priority?: number;
  assignee?: string;
  labels: Option[];
}

export function CreateForm(props: Props) {
  const { data, teams, priorities, onCreate, onCancel } = props;
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const [initialTeamId] = React.useState(localStorage.getItem(Constants.teamCacheKey) || teams[0]?.id);
  const [includeMessage] = React.useState<boolean>(
    localStorage.getItem(Constants.includeMessagePreferenceKey) === "true"
  );
  const [createIssueResult, createIssue] = useMutation<ICreateIssueMutation>(CreateIssueMutation);
  const [, updateIssue] = useMutation<IIssueUpdateMutation>(UpdateIssueMutation);
  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const fetching = createIssueResult.fetching || createAttachmentResult.fetching;
  const quotedMessage = React.useMemo(
    () =>
      (data.messages[0]?.body ?? "")
        .trim()
        .split("\n")
        .map(row => `> ${row}`)
        .join("\n"),
    [data]
  );
  const [descriptionHasImages, setDescriptionHasImages] = React.useState(hasImages(quotedMessage));

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (fetching) {
      setSubmitting(false);
      return;
    }

    localStorage.setItem(Constants.teamCacheKey, values.team);
    // If the description has images, we wait until the attachment is created and then set description to make
    // sure the Front attachment IDs can resolve to real image URLs
    setDescriptionHasImages(hasImages(values.description));
    const issueResult = await createIssue({
      teamId: values.team,
      title: values.title,
      description: descriptionHasImages ? undefined : values.description,
      assigneeId: values.assignee && values.assignee.length > 0 ? values.assignee : undefined,
      labelIds: values.labels.map(option => option.value) || [],
      priority: Number(values.priority),
    });
    const issue = issueResult.data?.issueCreate?.issue;

    if (issue) {
      const topic = await Front.addTopic(issue.url, `${issue.identifier} ${issue.title}`);
      await createAttachment(attachmentForFrontConversation(issue.id, { ...data, linkId: topic.id }));

      if (descriptionHasImages) {
        await updateIssue({ issueUpdateId: issue.id, description: values.description });
      }

      setSubmitting(false);
      onCreate();
    }
  };

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  function handleIncludeMessage(form: FormikProps<FormValues>, checked: boolean) {
    if (checked) {
      const cleanedDescription = form.values.description || "";
      form.setFieldValue("description", `${quotedMessage}\n\n${cleanedDescription}`);
    } else if (quotedMessage && form.values.description.startsWith(quotedMessage)) {
      form.setFieldValue("description", form.values.description.replace(quotedMessage, "").trim());
    }
    localStorage.setItem(Constants.includeMessagePreferenceKey, checked.toString());
  }

  return (
    <Container>
      <Formik
        initialValues={{
          title: data.subject || "",
          description: includeMessage ? quotedMessage : "",
          includeMessage,
          team: initialTeamId,
          labels: [],
        }}
        validationSchema={NewIssueSchema}
        onSubmit={handleSubmit}
      >
        {(form: FormikProps<FormValues>) => (
          <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
            <FormLabel htmlFor="title">Title</FormLabel>
            <div>
              <FormikField name="title">
                {({ field }: FieldProps<string, FormValues>) => (
                  <>
                    <Input id="title" autoFocus {...field} stretched />

                    {form.submitCount > 0 && form.touched.title && form.errors.title && (
                      <InputError>{form.errors.title}</InputError>
                    )}
                  </>
                )}
              </FormikField>
            </div>

            <FormLabel htmlFor="description">Description</FormLabel>
            <div>
              <FormikField name="description">
                {({ field }: FieldProps<string, FormValues>) => (
                  <StyledTextareaAutosize
                    as={TextareaAutosize}
                    id="description"
                    ref={textareaRef}
                    stretched
                    minRows={5}
                    maxRows={10}
                    {...field}
                  />
                )}
              </FormikField>
              <FormikField name="includeMessage" type="checkbox">
                {({ field }: FieldProps<string, FormValues>) => (
                  <Flex align="center" gap={4}>
                    <Toggle
                      id="includeMessage"
                      small
                      {...field}
                      onChange={e => {
                        handleIncludeMessage(form, e.target.checked);
                        field.onChange(e);
                        textareaRef.current?.focus();
                      }}
                    />
                    <Text id="includeMessage" as="label" type="mini" color="labelBase">
                      Include message
                    </Text>
                  </Flex>
                )}
              </FormikField>
            </div>

            {descriptionHasImages && (
              <div>
                <Text type="mini" color="labelBase">
                  <b>Note:</b> If this conversation is in a private inbox, Linear will not be able to download images
                  while creating this issue due to Front API limitations.
                </Text>
              </div>
            )}

            <FormikField name="team">
              {({ field }: FieldProps<string, FormValues>) => {
                const teamOptions = teams.map(team => ({ value: team.id, label: team.name }));
                const initialTeam = teamOptions.find(option => option.value === initialTeamId);

                return (
                  <>
                    <FormLabel htmlFor="team">Team</FormLabel>
                    <div>
                      <Select
                        id="team"
                        {...field}
                        defaultValue={initialTeam}
                        options={teamOptions}
                        value={teamOptions ? teamOptions.find(option => option.value === field.value) : ""}
                        onChange={(event: ValueType<Option, false>) => {
                          form.setFieldValue("team", event?.value);
                        }}
                        noOptionsMessage={() => "No matching team found"}
                        placeholder=""
                      />
                    </div>
                  </>
                );
              }}
            </FormikField>

            <FormikField name="priority">
              {({ field }: FieldProps<number, FormValues>) => {
                const priorityOptions = priorities.map(priority => ({
                  value: priority.priority + "",
                  label: priority.label,
                }));

                return (
                  <>
                    <FormLabel htmlFor="priority">Priority</FormLabel>
                    <div>
                      <Select
                        id="priority"
                        {...field}
                        options={priorityOptions}
                        value={priorityOptions ? priorityOptions.find(option => option.value === field.value + "") : ""}
                        onChange={(event: ValueType<Option, false>) => {
                          form.setFieldValue("priority", event?.value);
                        }}
                        noOptionsMessage={() => "No matching priority found"}
                        placeholder=""
                      />
                    </div>
                  </>
                );
              }}
            </FormikField>

            <TeamFormFields form={form} teamId={form.values.team} />

            <Buttons>
              <Button kind="primary" type="submit" disabled={form.isSubmitting}>
                {form.isSubmitting ? "Creating…" : "Create issue"}
              </Button>
              <Button kind="link" onClick={onCancel}>
                Cancel
              </Button>
            </Buttons>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 4px;
`;

const StyledForm = styled(Form)`
  > div {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;

  > button:first-child {
    margin-right: 12px;
  }
`;

// -- Team related form fields

const TeamFormFields = ({ teamId, form }: { form: FormikProps<FormValues>; teamId?: string }) => {
  const { color } = useTheme();
  const [result] = useQuery<ITeamQuery>({
    query: TeamQuery,
    variables: { teamId },
    pause: teamId === undefined,
  });
  const loaded = result.fetching === false && result.data;
  const users = result.data?.team?.members.nodes || [];
  const labels = result.data?.team?.labels.nodes || [];
  const allowInput = !!loaded && !!teamId;

  const userOptions = [
    { label: "Unassigned", value: "", color: color.labelMuted },
    ...users.map(user => ({ value: user.id, label: user.name })),
  ];

  const labelGroups = labels.filter(label => labels.find(l => l.parent?.id === label.id));
  const rootLabels = labels.filter(label => !labelGroups.includes(label));

  const labelOptions = SortHelper.natural(rootLabels, label => `${label.parent?.name || ""} ${label.name}`).map(
    label => {
      return {
        label: label.parent ? `${label.parent.name} → ${label.name}` : label.name,
        value: label.id,
        color: label.color,
      };
    }
  );

  // Custom label styles
  interface LabelData {
    data: { color: string };
  }
  const labelStyles = {
    option: (styles: {}, { data }: LabelData) => ({ ...styles, ...dot(data.color) }),
    multiValue: (styles: {}) => {
      return {
        ...styles,
        marginTop: 1,
        height: 23,
        backgroundColor: color.bgBase,
        borderRadius: 30,
        border: `1px solid ${color.bgBorder}`,
      };
    },
    multiValueLabel: (styles: {}, { data }: LabelData) => ({
      ...styles,
      ...dot(data.color),
      fontWeight: 500,
      fontSize: 13,
      color: color.labelTitle,
    }),
    multiValueRemove: (styles: {}) => ({
      ...styles,
      paddingLeft: 2,
      marginLeft: 2,
      color: color.labelFaint,
      fontWeight: 100,
      borderLeft: `1px solid transparent`,
      ":hover": {
        color: color.labelBase,
        borderLeft: `1px solid ${color.bgBorder}`,
      },
    }),
  };

  return (
    <>
      <FormikField name="assignee">
        {({ field }: FieldProps<string, FormValues>) => (
          <>
            <FormLabel htmlFor="assignee">Assignee</FormLabel>
            <div>
              <Select
                id="assignee"
                {...field}
                options={userOptions}
                value={userOptions ? userOptions.find(option => option.value === field.value) : ""}
                onChange={(event: ValueType<Option, false>) => {
                  form.setFieldValue("assignee", event?.value);
                }}
                noOptionsMessage={() => "No matching user found"}
                placeholder=""
                isDisabled={!allowInput}
              />
            </div>
          </>
        )}
      </FormikField>
      <FormikField name="labels">
        {({ field }: FieldProps<Option[], FormValues>) => {
          return (
            <>
              <FormLabel htmlFor="labels">Labels</FormLabel>
              <div>
                <Select
                  id="labels"
                  isMulti
                  isClearable={false}
                  options={labelOptions}
                  onChange={(event: ValueType<Option, true>) => {
                    form.setFieldValue(
                      "labels",
                      // Enforce unique labels within groups. Reverse ensures that the last selected label is the one that
                      // is retained.
                      uniqBy(Array.from(event).reverse(), (option: Option) => {
                        const label = labels.find(l => l.id === option.value);
                        const parent = labelGroups.find(group => group.id === label?.parent?.id);
                        return parent?.id || option.value;
                      }).reverse()
                    );
                  }}
                  value={field.value}
                  noOptionsMessage={() => "No matching label found"}
                  name={field.name}
                  placeholder=""
                  isDisabled={!allowInput}
                  styles={
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    labelStyles as any
                  }
                  components={{ ...defaultSelectProps.components, MultiValueRemove }}
                />
              </div>
            </>
          );
        }}
      </FormikField>
    </>
  );
};

const StyledTextareaAutosize = styled(Input)`
  appearance: none;
  -webkit-appearance: none;
  resize: none;
`;

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 8,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 8,
    width: 8,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <DeleteIcon />
    </components.MultiValueRemove>
  );
};

const DeleteIcon = styled(CloseIcon)`
  flex-shrink: 0;
  height: 10px;
  width: 10px;
  margin-bottom: -2px;
`;
