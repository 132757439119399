import omit from "lodash/omit";
import type { FrontData } from "~/types";
import { formatIssueTitle } from "./formattingUtils";

/** Helper method to convert Front data into attachment metadata. */
export function attachmentForFrontConversation(issueId: string, data: FrontData & { linkId: string }) {
  const title = formatIssueTitle(data);
  return {
    issueId,
    title,
    subtitle: data.subject === title ? undefined : data.subject,
    url: data.url,
    metadata: {
      id: data.conversationId,
      frontMessageId: data.messageId,
      title: data.subject,
      frontLinkId: data.linkId,
      frontConversationId: data.conversationId,
      messages: data.messages.map(message => omit(message, "status")),
    },
  };
}
