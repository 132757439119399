import React, { useEffect } from "react";
import Front from "@frontapp/plugin-sdk";
import TurndownService from "turndown/lib/turndown.browser.es";
import type { FrontData } from "../types";

const turndownService = new TurndownService();

turndownService
  // Parse out image CID values so we can download them using the API: https://dev.frontapp.com/reference/attachments
  .addRule("frontInlineImages", {
    filter: node => node.hasAttribute("front-cid"),
    replacement: (content, node) => {
      if ("attributes" in node) {
        const frontCidForImage = node.attributes.getNamedItem("front-cid")?.value;
        return `![](${frontCidForImage})`;
      }
      return content;
    },
  })
  // Prevent the contents of style tags getting added to the message body
  .addRule("removeStyleTags", {
    filter: "style",
    replacement: () => "",
  });

type Props = {
  children(props: { data: FrontData }): JSX.Element;
};

/**
 * Load Front ticket data from the parent before showing the app
 */
export function LoadFrontData(props: Props) {
  const [data, setData] = React.useState<FrontData | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadData(context: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const conversation = (context as any).conversation;
    const messages = [];
    const message = (await Front.listMessages()).results?.[0];
    let bodyHtml = message?.content?.body;

    if (bodyHtml?.length > 0) {
      // Strip signatures and original user messages
      const stripStartLocation = [
        bodyHtml.indexOf('<br><div class="front-signature">'),
        bodyHtml.indexOf('<br><blockquote type="cite" class="front-blockquote">'),
      ].find(location => location > 0);
      bodyHtml = stripStartLocation ? bodyHtml.substring(0, stripStartLocation) : bodyHtml;

      // Convert HTML into Markdown body and remove Google Group unsubscribe line
      const messageBody = bodyHtml.trim()
        ? turndownService.turndown(bodyHtml).replace(/^To unsubscribe from this group.*\n?/m, "")
        : "";

      const subjectParts = [];
      subjectParts.push(message.from.name ? `${message.from.name} (${message.from.handle})` : message.from.handle);
      subjectParts.push("to");
      subjectParts.push(message.to.map((to: { handle: string }) => to.handle).join(", "));

      messages.push({
        subject: subjectParts.join(" "),
        body: messageBody,
        timestamp: message.date,
        status: message.status,
      });
    }

    setData({
      conversationId: conversation.id,
      messageId: message?.id,
      subject: conversation.subject,
      blurb: conversation.blurb,
      // Front "discussions" don't have a recipient name
      fromName: conversation.recipient?.name,
      fromHandle: conversation.recipient?.handle,
      messages,
      url: `https://app.frontapp.com/open/${conversation.id}`,
      userId: context.teammate.email,
    });
  }

  useEffect(() => {
    Front.contextUpdates.subscribe(context => {
      if (context.type === "singleConversation") {
        void loadData(context);
      } else {
        setData(undefined);
      }
    });
  }, []);

  return data ? props.children({ data }) : <div />;
}
