import * as React from "react";

import { Icon, type IconProps } from "../Icon";

export function ChevronIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 9 5" width="9" height="5" {...props}>
      <svg>
        <path d="M1.915.557a.667.667 0 0 0-.943.943l2.862 2.862a.942.942 0 0 0 1.333 0L8.028 1.5a.667.667 0 0 0-.943-.943L4.5 3.14 1.915.557Z" />
      </svg>
    </Icon>
  );
}
