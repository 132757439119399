import React from "react";
import styled from "styled-components";
import { truncate } from "@linear/orbiter/styles/mixins";
import type { Label as IssueLabel } from "../queries";

type Props = {
  label: IssueLabel;
};

export const Label = (props: Props) => {
  const { label } = props;
  return (
    <LabelContainer>
      <LabelDot color={label.color} />
      <LabelText>{label.name}</LabelText>
    </LabelContainer>
  );
};

const LabelContainer = styled.span`
  display: inline-flex;
  align-items: center;
  position: relative;

  padding: 0 8px 0 8px;
  height: 22px;
  max-width: 180px;
  border-radius: 30px;
  border: 1px solid ${props => props.theme.color.bgBorder};
`;

interface LabelDotProps {
  color: string;
}

export const LabelDot = styled("div")<LabelDotProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${props => props.color};
  flex-shrink: 0;
`;

const LabelText = styled.span`
  margin-left: 6px;
  display: block;
  ${truncate()};
`;
