import React, { useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "focus-visible";
import Front from "@frontapp/plugin-sdk";
import { lightThemeRefresh } from "@linear/orbiter/styles/themes/lightThemeRefresh";
import { BaseStyles } from "@linear/orbiter/styles/base";
import { Root } from "./views/Root";
import { Config } from "./config";

const lightTheme = lightThemeRefresh();
function App() {
  useEffect(() => {
    Front.contextUpdates.subscribe(context => {
      if (!Config.PROD) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).FRONT_CONTEXT = context;
      }
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <Root />
        <GlobalStyle />
        <BaseStyles />
      </ThemeProvider>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  html {
    min-height: auto;
    overflow-y: auto;
  }
  html, body, #root {
    height: 100%;
    max-height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
  #root > div {
    min-height: auto;
    display: flex;
    flex-direction:column;
    flex: 1;
    max-height: 100%;
  }
`;

export default App;
