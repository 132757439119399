import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { Button } from "@linear/orbiter/components/Button";
import type { Attachment } from "../queries";
import type { Team, FrontData, Priority } from "../types";
import { IssueWidget } from "../components/IssueWidget";
import { CreateForm } from "./CreateForm";
import { LinkIssue } from "./LinkIssue";

type View = "main" | "create" | "link";

type Props = {
  /** Front conversation information. */
  data: FrontData;
  teams: Team[];
  priorities: Priority[];
  attachments: Attachment[];
  /** Reload attachment data. */
  reloadData(): void;
};

export function Main(props: Props) {
  const { data, reloadData, teams, priorities, attachments } = props;
  const [view, setView] = React.useState<View>("main");

  const handleReload = () => {
    reloadData();
    setView("main");
  };

  if (view === "create") {
    return (
      <CreateForm
        teams={teams}
        priorities={priorities}
        data={data}
        onCreate={handleReload}
        onCancel={() => setView("main")}
      />
    );
  }

  if (view === "link") {
    return <LinkIssue data={data} onLink={handleReload} onCancel={() => setView("main")} />;
  }

  return (
    <Flex column gap={18}>
      <Buttons>
        <Button kind="secondary" icon={<CreateIcon />} onClick={() => setView("create")}>
          Create Issue
        </Button>
        <Button kind="secondary" icon={<LinkIcon />} onClick={() => setView("link")}>
          Link Issue
        </Button>
      </Buttons>
      <Flex column gap={24}>
        {attachments.map(attachment => (
          <IssueWidget
            key={attachment.id}
            attachmentId={attachment.id}
            issue={attachment.issue}
            onUnlink={reloadData}
            allowUnlink
          />
        ))}
      </Flex>
    </Flex>
  );
}

const CreateIcon = (props: {}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.7826 2.26359C14.1313 1.69123 14.0606 0.931152 13.5705 0.434915C13.0757 -0.0660681 12.3153 -0.134235 11.7483 0.230412C12.2123 1.09277 12.9198 1.79999 13.7826 2.26359Z" />
      <path d="M10.8171 1.11829L5.78472 6.18C6.77457 6.47517 7.57699 7.21908 7.95006 8.18198L12.9064 3.20208C12.0535 2.68436 11.3369 1.9696 10.8171 1.11829Z" />
      <path d="M6.91486 9.17614C6.80538 8.1186 5.91913 7.30487 4.8592 7.29183C4.53827 7.92804 4.21105 8.90848 4.01729 9.53109C3.93355 9.80017 4.16675 10.0527 4.44262 9.99047C5.16831 9.82683 6.36057 9.52165 6.91486 9.17614Z" />
      <path d="M3.75 1C1.67893 1 0 2.67893 0 4.75V10.25C0 12.3211 1.67893 14 3.75 14H10.2501C12.3212 14 14.0001 12.3211 14.0001 10.25V7C14.0001 6.58579 13.6643 6.25 13.2501 6.25C12.8359 6.25 12.5001 6.58579 12.5001 7V10.25C12.5001 11.4926 11.4927 12.5 10.2501 12.5H3.75C2.50736 12.5 1.5 11.4926 1.5 10.25V4.75C1.5 3.50736 2.50736 2.5 3.75 2.5H6C6.41421 2.5 6.75 2.16421 6.75 1.75C6.75 1.33579 6.41421 1 6 1H3.75Z" />
    </svg>
  );
};

const LinkIcon = (props: {}) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.75A3.75 3.75 0 013.75 0H5a.75.75 0 010 1.5H3.75A2.25 2.25 0 001.5 3.75v5.5a2.25 2.25 0 002.25 2.25h6.5a2.25 2.25 0 002.25-2.25V7A.75.75 0 0114 7v2.25A3.75 3.75 0 0110.25 13h-6.5A3.75 3.75 0 010 9.25v-5.5zm12.447-2.19L11.44.555a1.9 1.9 0 00-2.683 0l-1.342 1.34a1.426 1.426 0 00-.308 1.551.474.474 0 10.876-.362.474.474 0 01.103-.515l1.341-1.341a.949.949 0 011.342 0l1.006 1.005a.949.949 0 010 1.342l-1.343 1.34a.481.481 0 01-.517.102.474.474 0 10-.363.877 1.435 1.435 0 001.55-.309l1.342-1.341a1.9 1.9 0 00.002-2.684zM6.512 7a.517.517 0 01-.362-.148.512.512 0 010-.724l3.982-3.984a.512.512 0 01.724.724L6.874 6.852A.517.517 0 016.512 7zm1.607-.602a.474.474 0 01.773.154 1.427 1.427 0 01-.308 1.551L7.242 9.445a1.896 1.896 0 01-2.681 0L3.554 8.439a1.9 1.9 0 010-2.683l1.343-1.342a1.438 1.438 0 011.55-.309.474.474 0 01-.362.877.48.48 0 00-.517.103L4.225 6.426a.949.949 0 000 1.342L5.23 8.774a.948.948 0 001.34 0l1.342-1.342a.474.474 0 00.103-.517.475.475 0 01.103-.517z"
      />
    </svg>
  );
};

const Buttons = styled.div`
  display: flex;
  column-gap: 16px;

  > button {
    flex: 1;
  }
`;
