import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { LoginButton } from "~/components/LoginButton";

export function Login(props: { onSuccess(accessToken: string): void }) {
  return (
    <Flex auto column align="center" justify="center" gap={24}>
      <Flex column align="center" gap={24}>
        <LogoImg src="images/logo_128.png" />
        <TextContainer>
          <Text type="regularPlus" align="center">
            Link and create issues within Front
          </Text>
        </TextContainer>
        <ButtonContainer>
          <LoginButton onSuccess={props.onSuccess} />
        </ButtonContainer>
      </Flex>
    </Flex>
  );
}

const TextContainer = styled.div`
  max-width: 160px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  max-width: 220px;
  text-align: center;
`;

const LogoImg = styled.img`
  width: 64px;
`;
