import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { Formik, Field as FormikField, Form, type FormikHelpers, type FormikProps, type FieldProps } from "formik";
import * as Yup from "yup";
import { useClient, useMutation } from "urql";
import throttle from "lodash/throttle";
import Front from "@frontapp/plugin-sdk";
import { Button } from "@linear/orbiter/components/Button";
import { Text } from "@linear/orbiter/components/Text";
import { Toggle } from "@linear/orbiter/components/Toggle";
import { Flex } from "@linear/orbiter/components/Flex";
import { Input } from "@linear/orbiter/components/Input";
import { AsyncSelect } from "~/components/Select";
import { attachmentForFrontConversation } from "~/utils/attachmentForFrontConversation";
import { hasImages } from "~/utils/formattingUtils";
import {
  CreateAttachmentMutation,
  type ICreateAttachmentMutation,
  type ISearchIssueQuery,
  type Issue,
  SearchIssueQuery,
} from "../queries";
import type { FrontData } from "../types";
import { Constants } from "../constants";
import { IssueWidget } from "../components/IssueWidget";

const LinkIssueSchema = Yup.object().shape({
  issue: Yup.object().required("Required"),
});

type Props = {
  data: FrontData;
  onLink(): void;
  onCancel(): void;
};

interface FormValues {
  issue?: Issue;
  comment: string;
  includeComment?: boolean;
}

export function LinkIssue(props: Props) {
  const { data, onLink, onCancel } = props;
  const descriptionHasImages = data.messages.find(m => hasImages(m.body));
  const includeComment = localStorage.getItem(Constants.commentCacheKey) === "true" || false;

  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const { fetching } = createAttachmentResult;

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (!values.issue || fetching) {
      setSubmitting(false);
      return;
    }
    const commentBody = values.includeComment && values.comment?.length ? values.comment : undefined;

    const topic = await Front.addTopic(values.issue.url, `${values.issue.identifier} ${values.issue.title}`);
    localStorage.setItem(Constants.commentCacheKey, values.includeComment ? "true" : "false");

    await createAttachment({
      ...attachmentForFrontConversation(values.issue.id, { ...data, linkId: topic.id }),
      commentBody,
    });
    setSubmitting(false);
    onLink();
  };

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Flex column gap={10}>
        {descriptionHasImages && (
          <Text type="mini" color="labelBase">
            <b>Note:</b> If this conversation is in a private inbox, Linear will not be able to download images while
            attaching this conversation to an issue due to Front API limitations.
          </Text>
        )}
        <Formik
          initialValues={{
            comment: "",
            includeComment,
          }}
          validationSchema={LinkIssueSchema}
          onSubmit={handleSubmit}
        >
          {(form: FormikProps<FormValues>) => (
            <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
              <IssueDropdown
                onSelect={value => {
                  form.setFieldValue("issue", value);
                }}
                autoFocus
              />

              {form.values.issue && (
                <>
                  <IssueWidget issue={form.values.issue} />

                  <FormikField name="includeComment" type="checkbox">
                    {({ field }: FieldProps<string, FormValues>) => (
                      <Flex align="center" gap={12}>
                        <Toggle id="includeComment" {...field} />
                        <Text as="label" htmlFor="includeComment" type="smallPlus">
                          Include comment
                        </Text>
                      </Flex>
                    )}
                  </FormikField>

                  {form.values.includeComment && (
                    <FormikField name="comment">
                      {({ field }: FieldProps<string, FormValues>) => (
                        <StyledTextareaAutosize minRows={5} maxRows={10} stretched autoFocus {...field} />
                      )}
                    </FormikField>
                  )}
                </>
              )}

              <Buttons>
                <Button kind="primary" type="submit" disabled={form.values.issue === undefined || form.isSubmitting}>
                  {form.isSubmitting ? "Linking…" : "Link ticket"}
                </Button>
                <Button kind="link" onClick={onCancel}>
                  Cancel
                </Button>
              </Buttons>
            </StyledForm>
          )}
        </Formik>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  padding: 4px;
  max-width: 100%;
  min-height: 350px;
  overflow: auto;
`;

const StyledForm = styled(Form)`
  > div,
  > textarea {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;

  > button:first-child {
    margin-right: 12px;
  }
`;

interface IssueDropdownProps {
  onSelect(issue: Issue): void;
  autoFocus?: boolean;
}

const issueCache: Record<Issue["id"], Issue | undefined> = {};

/**
 * Dropdown to search issues.
 */
const IssueDropdown = (props: IssueDropdownProps) => {
  const { onSelect, ...rest } = props;
  const client = useClient();
  // Used to track search input to only return latest search value when throttling input
  const lastSearchInput = React.useRef<string | undefined>();

  const fetchIssues = (
    inputValue: string,
    callback: (
      opts: {
        value: string;
        label: string;
      }[]
    ) => void
  ) => {
    if (inputValue.length === 0) {
      callback([]);
    }

    lastSearchInput.current = inputValue;
    void client
      .query<ISearchIssueQuery>(SearchIssueQuery, { searchQuery: inputValue, includeComments: true })
      .toPromise()
      .then(result => {
        const issues =
          result.data?.searchIssues.nodes.map(issue => ({
            value: issue.id,
            label: `${issue.identifier} ${issue.title}`,
          })) || [];
        for (const issue of result.data?.searchIssues.nodes || []) {
          issueCache[issue.id] = issue;
        }
        if (inputValue === lastSearchInput.current) {
          callback(issues);
        }
      });
  };
  const debouncedFetch = throttle(fetchIssues, 250, { leading: true, trailing: true });

  return (
    <AsyncSelect
      {...rest}
      cacheOptions
      defaultOptions
      loadOptions={debouncedFetch}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(data: any) => {
        const issue = issueCache[data.value];
        if (issue) {
          onSelect(issue);
        }
      }}
      noOptionsMessage={({ inputValue }) => (inputValue ? "No matching issues found" : "Type to search…")}
    />
  );
};

const StyledTextareaAutosize = styled(Input).attrs({ as: TextareaAutosize })`
  appearance: none;
  -webkit-appearance: none;
  resize: none;
`;
