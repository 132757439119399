import React from "react";
import styled, { keyframes } from "styled-components";
import { Flex } from "./Flex";

const FADEOUT = 150; // ms

/** Zooming Linear loading animation. */
export const LinearLoading = (props: { loaded: boolean }) => {
  const [visible, setVisible] = React.useState(false);
  const loaded = React.useRef(props.loaded);

  React.useEffect(() => {
    let timer: number | undefined;
    if (props.loaded) {
      loaded.current = true;
      timer = window.setTimeout(() => {
        setVisible(true);
      }, FADEOUT);
    }

    return () => {
      timer && window.clearTimeout(timer);
    };
  }, [props.loaded]);

  if (!visible) {
    null;
  }

  return (
    <Background loaded={loaded.current}>
      <Container>
        <BackgroundSvg width="64" height="64" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="256" cy="256" r="256" fill="url(#bkg)" />

          <defs>
            <linearGradient id="bkg" x1="0" y1="0" x2="0" y2="512" gradientUnits="userSpaceOnUse">
              <stop stopColor="#E0E4EF" />
              <stop offset="1" stopColor="#B0B5C0" />
            </linearGradient>
          </defs>
        </BackgroundSvg>

        <svg width="64" height="64" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M233.929 387.099L124.901 278.071C134.214 333.8 178.2 377.786 233.929 387.099Z" fill="url(#white)" />
          <path
            d="M123.279 248.6L263.4 388.721C271.712 388.264 279.821 387.045 287.658 385.13L126.87 224.342C124.955 232.179 123.736 240.288 123.279 248.6Z"
            fill="url(#white)"
          />
          <path
            d="M133.856 203.479L308.521 378.144C314.874 375.408 320.971 372.192 326.766 368.54L143.46 185.234C139.808 191.029 136.592 197.126 133.856 203.479Z"
            fill="url(#white)"
          />
          <path
            d="M155.304 169.228C179.679 140.966 215.75 123.077 256 123.077C329.411 123.077 388.923 182.589 388.923 256C388.923 296.25 371.034 332.321 342.772 356.696L155.304 169.228Z"
            fill="url(#white)"
          />
          <defs>
            <linearGradient
              id="white"
              x1="388.923"
              y1="388.721"
              x2="388.923"
              y2="123.077"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.85" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      </Container>
    </Background>
  );
};

const Background = styled(Flex).attrs({
  column: true,
  auto: true,
  center: true,
})<{ loaded: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: ${props => props.theme.color.bgBase};
  opacity: ${props => (props.loaded ? 0 : 1)};
  transition: opacity ${FADEOUT}ms ease-in-out;
  pointer-events: none;
`;

const logoAnimation = keyframes`
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    transform: scale(0.4);
  }
`;

const backgroundAnimation = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const BackgroundSvg = styled.svg`
  animation: ${backgroundAnimation} 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) both;
`;

const Container = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
  animation: ${logoAnimation} 10s cubic-bezier(0.165, 0.84, 0.44, 1) both;

  svg {
    position: absolute;
    left: 0;
    top: 0;
  }
`;
