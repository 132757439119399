import type { FrontData } from "../types";

/**
 * Build issue attachment title from the conversation.
 *
 * @param data Front conversation ticket
 */
export const formatIssueTitle = (data: FrontData): string => {
  return data.fromName || data.fromHandle
    ? `Message ${data.messages[0]?.status === "outbound" ? "to" : "from"} ${data.fromName || data.fromHandle}`
    : data.subject;
};

/**
 * Checks if the message has any images in it.
 *
 * @param message The message body
 */
export const hasImages = (message?: string): boolean => {
  if (!message) {
    return false;
  }
  const markdownImageRegex = /!\[.*\]\((?<relativeUrl>.*\))/g;
  const match = message.match(markdownImageRegex);
  return match !== null && match.length > 0;
};
