import React from "react";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from "urql";
import { Layout } from "~/components/Layout";
import { Config } from "../config";
import { Constants } from "../constants";
import { LoadFrontData } from "./LoadFrontData";
import { Login } from "./Login";

export function Root() {
  const [accessToken, setAccessToken] = React.useState<string | null>(localStorage.getItem(Constants.AUTH_TOKEN));

  const handleLogin = (token: string) => {
    setAccessToken(token);
    localStorage.clear();
    localStorage.setItem(Constants.AUTH_TOKEN, token);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAccessToken(null);
  };

  const client = React.useMemo(
    () =>
      createClient({
        url: Config.GRAPHQL_URL,
        fetchOptions: () => {
          const token = localStorage.getItem(Constants.AUTH_TOKEN);
          return {
            headers: { authorization: token ? `Bearer ${token}` : "" },
          };
        },
        exchanges: [
          dedupExchange,
          cacheExchange,
          errorExchange({
            onError(error) {
              const errorMessage = error.message.toLowerCase();
              // 401 - Log out the user
              if (errorMessage.includes("authentication failed") || errorMessage.includes("authentication required")) {
                setAccessToken(null);
              }
            },
          }),
          fetchExchange,
        ],
      }),
    [accessToken]
  );

  return (
    <UrqlProvider value={client}>
      {accessToken ? (
        <LoadFrontData>{({ data }) => <Layout data={data} onLogout={handleLogout}></Layout>}</LoadFrontData>
      ) : (
        <Login onSuccess={handleLogin} />
      )}
    </UrqlProvider>
  );
}
